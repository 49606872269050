var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"draggableContainer",staticClass:"draggableContainer",style:({
    zIndex: _vm.zIndex,
    'background-color': _vm.contentColor,
  }),attrs:{"id":"resizable"},on:{"mousedown":_vm.zIndexToggle}},[_c('div',{staticClass:"draggableHeader",on:{"mousedown":_vm.dragMouseDown}},[_c('div',{staticClass:"draggable-header-text"},[_vm._v(_vm._s(_vm.content.header))]),_c('div',{staticClass:"title-bar-controls",on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('Button',{attrs:{"aria-label":"Minimize"},on:{"click":function($event){return _vm.windowState('minimize')}}}),_c('Button',{attrs:{"aria-label":"Maximize"},on:{"click":function($event){return _vm.windowState('maximize')}}}),_c('Button',{attrs:{"aria-label":"Close"},on:{"click":function($event){$event.stopPropagation();return _vm.hideContainer.apply(null, arguments)}}})],1)]),_c('div',{staticClass:"header"},[_c('h3',[_vm._v(_vm._s(_vm.content.title))])]),_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"mediaBox"},[_c('h5',[_vm._v("Brief demo")]),_c('vimeo-player',{attrs:{"video-id":this.content.videoID,"player-height":300,"player-width":200}})],1),_vm._m(1),_vm._m(2),_c('div',{staticClass:"mediaBox"},[_c('GroupSVG',{staticClass:"svg",attrs:{"rel":"preload"}}),_c('h5',[_vm._v("Interaction with four students")])],1),_c('div',{staticClass:"mediaBox"},[_c('IlluSVG',{staticClass:"svg",attrs:{"rel":"preload"}}),_c('h5',[_vm._v("Device / Cloud model")])],1),_c('div',{staticClass:"mediaBox"},[_c('ScenarioSVG',{staticClass:"svg",attrs:{"rel":"preload"}}),_c('h5',[_vm._v("Scenario interaction")])],1),_vm._m(3),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mediaBox"},[_c('h5',[_vm._v("What is topico")]),_c('p',[_vm._v(" Topico is an online dictionary for the individual classes to use. It is aimed for the pupils in a sixth grade English class, designed on the basis of observations and empirical data that we have gathered and analyzed. It is used to store verbal descriptions of relevant words and topics learned in class in audio files. This project fits in the category of EduTech, which covers the study and ethical practice of facilitating learning and improving performance by creating, using and managing appropriate technological processes and resources ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mediaBox"},[_c('h5',[_vm._v("How it works")]),_c('p',[_vm._v(" The game works as following. Four pupils sit around the device. Then they push the main button on the top of the device, which makes four different words appear, where they only can see one word each. These are the main words. They all have three corresponding help words, which is related to the meaning of the main word. Then they push the button again, and the device will choose one of those four words. The pupil that the word is displayed for, will be instructed to explain that. When they press the button again, there will appear a help word, connected to the chosen word, on the three other screens. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mediaBox"},[_c('h5'),_c('p',[_vm._v(" Help words will appear one at a time, to secure that they can discuss and connect it to the chosen word. After this is done, they can press the button again, to record the explanation of the word. When this is done, the explanation is stored on the website, where they can find it connected to the chosen word. From the teacher’s perspective, we learned that she has had some quite struggling experiences with new technology, so we have made our website it in a way, where she only redefines the main words and help words. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mediaBox"},[_c('h5',[_vm._v("Concept")]),_c('p',[_vm._v(" It is the pupils themselves, who records these audio files. The game is suppose to encourage the pupils, to collaborate with their classmates, to make the most substantial description of every word, that they receive. The goal for this game, is not to make a flawless sentence, without any grammatical mistakes. It is suppose to make you practice your verbal skills, to gain experience in explaining specific things. It is equal to what is expected of you in the demands from the ministry in Denmark. From our collection of empirical data, we have learned that they use their native language more frequently, when discussing the meaning of words. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mediaBox"},[_c('h5',[_vm._v("Empirical data")]),_c('p',[_vm._v(" From the observations we made visiting an Elementary school, sixth grade english class, we got different views on the pupils and their work methods. We wrote notes about how they acted and used the English language compared to the Danish. We also saw, how they cooperated with each other and how they helped each other. Furthermore, we saw how the the pupil’s relation with the teacher was like. Information was gained with the methods from Ethnographic field methods, Guiding Principles of Ethnography(NaturalSettings). We also had small conversations and semi-structured interviews with the teacher and pupils which gave an insight in their daily work and activities. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mediaBox"},[_c('h5',[_vm._v("Findings")]),_c('p',[_vm._v(" Topico has been designed with the pupils learning abilities in mind. As a concept and product, the idea has essentially been to make a product that can aid learning among elementary school pupils. Putting this pursuit to fruition has taken then form as device and webapp solution, where both the relation between the pupils and teacher has been a major focus. From the field studies, several challenges raised to the surface in terms of how to teaching could be enhanced. One of these challenges was how some pupils was left of the discussions and dialogs in English class, and the dynamics of words exchange were mainly constituent of a few active pupils and the teacher. "),_c('br'),_c('br'),_vm._v(" Furthermore, the same issues were visible during group work, as the same active pupils would conduct the dialogs, and thus exclude the less active students. "),_c('br'),_c('br'),_vm._v(" This is where Topico fills in the blanks, enabling and encouraging an active learning space for all types of pupils, focusing on language exchange. Furthermore, the teacher can also assist more pupils at the same time as he/she can correct and set the degree of difficulty of the words used in each game round. "),_c('br'),_c('br'),_vm._v(" Whether this smart connected learning tool has a place in the classrooms of today remains to see, this will be up to further investigation and testing to clarify, however the analysis of the demand of such a tool is without doubt existent. ")])])
}]

export { render, staticRenderFns }